
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import RoundRadio from "@/components/RoundRadio.vue";
import {Getter} from "vuex-class";

@Component({
    name: "NotificationsManager",
    components: {RoundRadio}
})
export default class NotificationsManager extends mixins(VModel) {
    @Getter('isAgency') isAgency!: boolean;

    get notifications() {
        return this.innerValue;
    }

    get context() {
        return this.isAgency ? 'agency' : 'company';
    }

    // hasSMS(n:any) {
    //     return n?.includes('sms');
    // }
    hasEmail(n:any) {
        return n?.includes('email');
    }
    toggle(n:any, type:string) {
        if (n) {
            let p = n.indexOf(type);

            if (p !== -1) {
                n.splice(p, 1);
            } else {
                n.push(type);
            }
        }
    }
}
