
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IUser, IUserDB, LabelValue, ObjectID, StoreAction, StoreMutation} from "@/types";
import {formatErrors, isValidEmail, passwordStrength} from "@/helpers/commons";
import {Action, Getter, Mutation, State} from "vuex-class";
import clonedeep from "lodash.clonedeep";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import NotificationsManager from "@/components/NotificationsManager.vue";
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";

@Component({
    name: "Profile",
    components: {NotificationsManager, ListAutocompleteSelect, PicturePreview, SelectAutocomplete}
})
export default class Profile extends mixins(TitleManager) {
    $refs!: {
        bsCard: any,
        container: any
    };
    tmpUser: any = {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        picture: "",
        email: "",
        phone: "",
        position: "",
        agencyId: "",
        businessSectors: []
    };
    errors: any = {
        profile: {
            errors: {},
            global: {}
        },
        connexion: {
            errors: {},
            global: {}
        }
    }
    loading = false;
    loadingNotifications = false;

    tmpConnexion: any = {
        email: "",
        password: "",
        newPassword: "",
        confirmPassword: ""
    };

    passwordStrength = passwordStrength;
    successNotifications = false;
    successProfile = false;
    successPassword = false;
    successDomains = false;

    timeout:any = 0;
    timeoutNotifications:any = 0;

    pageTitle = this.$t('page.profile.title');

    view = "infos";

    @State('currentUser') currentUser!: IUser;

    @Getter('isAgency') isAgency!: boolean;
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('getJobOption') getJobOption!: any;
    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('isSupervisor')  isSupervisor!: boolean;

    @Mutation('setUser') setUser!: StoreMutation;

    @Action('auth/updatePassword') updatePassword!: StoreAction;
    @Action('actions/saveUserProfile') saveUserProfile!: StoreAction;
    @Action('actions/getGlobalConfiguration') getGlobalConfiguration!: StoreAction;

    get context() {
        return this.isAgency ? 'agency' : 'company';
    }

    get hasNotifications() {
        return Object.keys(this.$t('page.profile.notifications.types.' + this.context)).length;
    }

    get fullname() {
        return this.currentUser.firstname + " " + this.currentUser.lastname;
    }

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i:LabelValue) => i.value === id);
    }

    reloadBS() {
        this.$forceUpdate();
    }

    validateConnexion() {
        if (!this.tmpConnexion.password) {
            this.errors.connexion.errors.password = {id: "Validation.Any.Empty"};
        }
        if (this.tmpConnexion.newPassword !== this.tmpConnexion.confirmPassword) {
            this.errors.connexion.errors.confirmPassword = {id: "Validation.Password.MisMatch"};
        }
        if (!this.tmpConnexion.confirmPassword) {
            this.errors.connexion.errors.confirmPassword = {id: "Validation.Any.Empty"};
        }
        return !Object.keys(this.errors.connexion.errors).length;
    }

    async changeConnexion() {
        this.errors.connexion.errors = {};
        this.errors.connexion.global = [];

        if (this.tmpConnexion.confirmPassword !== this.tmpConnexion.newPassword) {
            this.errors.connexion.errors.confirmPassword = {id: 'Auth.NewPassword.NoMatch'};
        } else {
            try {

                if (this.validateConnexion()) {
                    await this.updatePassword(this.tmpConnexion);
                    this.tmpUser.username = this.tmpConnexion.username;
                    this.tmpConnexion.password = "";
                    this.tmpConnexion.newPassword = "";
                    this.tmpConnexion.confirmPassword = "";
                    this.successPassword = true;

                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }

                    this.timeout = setTimeout(() => {
                        this.successPassword = false;
                    }, 5000);
                }
            } catch(e) {
                const errors = formatErrors(e);
                this.errors.connexion.errors = errors.errors || [];
                this.errors.connexion.global = errors._global || [];
            }
        }
    }

    async update() {
        try {
            const userProfile:IUserDB = await this.saveUserProfile({
                email: this.tmpUser.email,
                firstname: this.tmpUser.firstname,
                position: this.tmpUser.position,
                lastname: this.tmpUser.lastname,
                phone: this.tmpUser.phone,
                picture: this.tmpUser.picture,
                businessSectors: this.tmpUser.businessSectors?.map((bs:any) => (typeof bs.value === 'number' && isNaN(bs.value) && bs.label) || bs.value) || [],
                notifications: this.tmpUser.notifications
            });
            await this.getGlobalConfiguration();
            await this.setUser(userProfile);
            this.tmpUser = clonedeep(this.currentUser);
            if (this.tmpUser.businessSectors) {
                // @ts-ignore;
                this.tmpUser.businessSectors = this.tmpUser.businessSectors.map((bs:any) => this.getBusinessSector(bs));
            }
        } catch (e) {
            throw e;
        }
    }

    async updateProfile() {
        this.loading = true;
        this.errors.profile.errors = {};
        this.errors.profile.global = [];
        try {
            await this.update();
            this.successProfile = true;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.successProfile = false;
            }, 5000);
        } catch(e) {
            this.successProfile = false;
            this.errors.profile.errors = e.errors || {};
            this.errors.profile.global = e._global || [];
        }
        this.loading = false;
    }

    async updateNotifications() {
        this.loadingNotifications = true;
        try {
            await this.update();
            this.successNotifications = true;

            if (this.timeoutNotifications) {
                clearTimeout(this.timeoutNotifications);
            }

            this.timeoutNotifications = setTimeout(() => {
                this.successNotifications = false;
            }, 5000);
        } catch(e) {
            this.successNotifications = false;
        }
        this.loadingNotifications = false;
    }

    async changeBS() {
        try {
            await this.update();
            this.successDomains = true;
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.successDomains = false;
            }, 5000);
        } catch (e) {
            console.log(e);
        }
    }

    mounted() {
        setTimeout(() => {
            if (+this.$route.query.bs === 1 && this.$refs?.bsCard?.$el) {
                this.$scrollTo(this.$refs.bsCard.$el, 400, {container: this.$refs.container.$el});
            }
        }, 500);
    }

    beforeMount() {
        this.tmpUser = clonedeep(this.currentUser);

        if (!this.tmpUser.businessSectors) {
            this.tmpUser.businessSectors = [];
        } else {
            // @ts-ignore;
            this.tmpUser.businessSectors = this.tmpUser.businessSectors.map((bs:any) => this.getBusinessSector(bs));
        }

        if (!this.tmpUser.notifications) {
            if (this.isAgency) {
                this.tmpUser.notifications = {
                    "worker_registration" : [],
                    "worker_accepted" : [],
                    "worker_declined" : [],
                    "worker_abort" : [],
                }
            }
        }
    }
}
